import React from "react";
import styles from "../Modules/Aboutme.module.css";
require("../index.css")
class Aboutme extends React.Component {
  render() {
    return (
      <div id="aboutme" className={styles.aboutme}>
          <div className={styles.container}>
              <div className={styles.bio}>
                  <h3>
                      My name is Soulaimane ELManioui, and I am full-stack software developer based in Marrakech - Morocco.
                  </h3>
                  <p>
                      I am currently working at <a target="_blank" rel="noopener noreferrer" href={"http://monarkit.net/"}>Monark-IT</a>
                      &nbsp;where I focus on solving software related problems, while also honing my skills through
                       continuous self-improvement.&nbsp;
                       Outside work, I enjoy going to the gym, cooking, 
                       and spending time with family.
                  </p>
                  <p>

                      Throughout my career, I have had the chance to learn and employ an array of technologies for diverse use cases. This journey has led me to navigate through numerous programming languages, frameworks, and tools, enriching my skill set and allowing me to craft innovative solutions that merge technology with practicality.
                  </p>
                  <p>
                      I'm always excited to embark on new challenges, work with inspiring teams, and contribute to projects that make a meaningful impact.               </p>
                  <p/>
                  <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className={"cta"} href={"/resume"}>
                      Resume
                      <svg xmlns="http://www.w3.org/2000/svg"
                           height="1em"
                           viewBox="0 0 512 512"
                           className={"icon"}
                      >
                          {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.*/}
                          <path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/>
                      </svg>
                  </a>
              </div>
              <div className={styles.skills}>
                  <span className={styles.tech}>PHP</span>
                  <span className={styles.tech}>Symfony</span>
                  <span className={styles.tech}>Python</span>
                  <span className={styles.tech}>Django</span>
                  <span className={styles.tech}>Java</span>
                  <span className={styles.tech}>SpringBoot</span>
                  <span className={styles.tech}>React.js</span>
                  <span className={styles.tech}>React Native</span>
                  <span className={styles.tech}>JavaScript</span>
                  <span className={styles.tech}>Jquery</span>
                  <span className={styles.tech}>Docker</span>
                  <span className={styles.tech}>Git & Github</span>
                  <span className={styles.tech}>CI/CD</span>
                  <span className={styles.tech}>Linux</span>
                  <span className={styles.tech}>SCRUM</span>
                  <span className={styles.tech}>UML</span>
                  <span className={styles.tech}>UNIT TESTING</span>
                  <span className={styles.tech}>RestAPI</span>
                  <span className={styles.tech}>HTML/CSS</span>
                  <span className={styles.tech}>SQL</span>
              </div>
          </div>
      </div>
    );
  }
}

export default Aboutme;
