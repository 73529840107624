import React from "react";
import styles from "../Modules/Home.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-scroll";
import {profilePhoto} from "../imports";
require("../index.css")

class Home extends React.Component {
  render() {
    return (
        <>
            <div className={styles.hero_section}>
                <div id="home" className={styles.content}>
                    <div className={styles.left_side}>
                        <h1>
                            Hi, I am Soulaimane,
                            <br />
                            a Full-Stack Developer
                        </h1>
                        <a className={"cta"}
                           target="_blank"
                           rel="noopener noreferrer"
                           href={"/resume"}>
                            Resume
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 height="1em"
                                 viewBox="0 0 512 512"
                                 className={"icon"}
                            >
                                {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.*/}
                                <path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/>
                            </svg>
                        </a>
                    </div>
                    <div className={styles.right_side}>
                        <img src={profilePhoto} alt="Soulaimane ELManioui" />
                    </div>
                </div>
            </div>
            <div className={styles.centered_link_container}>
                <Link
                    className={styles.primary_button}
                    to={"projects"}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={1000}
                >
                    <FontAwesomeIcon className={styles.inline_icon} icon={faArrowDown} />
                </Link>
            </div>
        </>
  );
  }
}

export default Home;
